<template>
  <v-container id="payment" fluid tag="section" style="height: calc(100% - 90px)" class="container-full-size">
    <v-col cols="12">
      <v-btn color="default" class="mr-0" @click="back()">
        {{ str['back'] }}
      </v-btn>
    </v-col>
    <client-payment :payment="payment" :page="true" />
  </v-container>
</template>

<script>
import Utils from '@/services/Utils'

export default {
  components: {
    ClientPayment: () => import('@/components/dashboard/client/Payment'),
  },
  data() {
    const paymentCache = Utils.getStorage('payment')
    const payment = paymentCache ? paymentCache : {}
    return {
      str: window.strings,
      user: Utils.getUser(),
      payment: payment,
    }
  },
  beforeMount: function () {
    if (!this.user) {
      return false
    }
    this.setPaymentMethods()
  },
  methods: {
    back: function () {
      this.$router.goBack()
    },
    setPaymentMethods: function () {
      this.payment.setValue = (key, value) => {
        if (key) {
          this.payment[key] = value
        } else {
          this.payment = value
        }
      }
    },
  },
}
</script>
